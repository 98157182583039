import speakingurl from 'speakingurl'

import MeerInformatie from '/images/statements/meer-informatie.raw.svg'
import Beweging from '/images/statements/beweging.raw.svg'
import Emoties from '/images/statements/emoties.raw.svg'
import GoedInVel from '/images/statements/goed-in-vel.raw.svg'
import Hobbys from '/images/statements/hobbys.raw.svg'
import HulpVragen from '/images/statements/hulp-vragen.raw.svg'
import Idee from '/images/statements/idee.raw.svg'
import IetsMeegemaakt from '/images/statements/iets-meegemaakt.raw.svg'
import Alleen from '/images/statements/ik-sta-er-alleen-voor.raw.svg'
import Klachten from '/images/statements/klachten.raw.svg'
import Religie from '/images/statements/religie.raw.svg'
import SociaalPersoon from '/images/statements/sociaal-persoon.raw.svg'
import Steun from '/images/statements/steun.raw.svg'
import TegenopZien from '/images/statements/tegenop-zien.raw.svg'
import Toekomstzorgen from '/images/statements/toekomstzorgen.raw.svg'
import VanAllesSpeelt from '/images/statements/van-alles-speelt.raw.svg'
import Voorbereiden from '/images/statements/voorbereiden.raw.svg'
import WerkNiet from '/images/statements/werk-niet.raw.svg'
import WerkOfVrijwilligerswerk from '/images/statements/werk-of-vrijwilligerswerk.raw.svg'
import WoonAlleen from '/images/statements/woon-alleen.raw.svg'
import WoonSamen from '/images/statements/woon-samen.raw.svg'

export const statements = {
  'cly70jm4b000016b61qhk0rnh': {
    id: 'cly70jm4b000016b61qhk0rnh',
    statement: 'Ik woon samen met anderen',
    icon: WoonSamen,
    showFrequency: false,
    formFields: {
      question: 'Hoe ziet dit eruit voor u?',
      options: createOptions([
        'Ik woon met mijn partner',
        'Ik woon met mijn (klein)kind(eren)',
        'Ik woon met anderen',
        'Ik woon met een ouder/verzorger',
        'Anders'
      ])
    }
  },
  'clymyvwto000108l6cyyvfw80': {
    id: 'clymyvwto000108l6cyyvfw80',
    statement: 'Ik woon alleen',
    icon: WoonAlleen,
    showFrequency: false,
    formFields: {
      question: 'Hoe ziet dit eruit voor u?',
      options: createOptions([
        'Ik ben alleenstaand',
        'Mijn partner is overleden',
        'Ik ben gescheiden',
        'Anders'
      ])
    }
  },
  'clymyz6ab000308l66e0ybtdc': {
    id: 'clymyz6ab000308l66e0ybtdc',
    statement: 'Ik ervaar steun van mensen in mijn omgeving',
    icon: Steun,
    showFrequency: false,
    formFields: {
      question: 'Door wie wordt u ondersteund?',
      options: createOptions([
        'Mijn partner',
        'Mijn kind(eren)',
        'Mijn broer(s)/zus(sen)',
        'Andere familieleden',
        'Vrienden',
        'Buren',
        'Collega’s',
        'Anders'
      ])
    }
  },
  'clymz6v4t000008macmercdvx': {
    id: 'clymz6v4t000008macmercdvx',
    statement: 'Ik sta er (meestal) alleen voor',
    icon: Alleen,
    showFrequency: false,
    formFields: {
      question: 'Wat is uw situatie?',
      options: createOptions([
        'Ik ben alleenstaand',
        'Mijn partner is overleden',
        'Weinig vrienden/kennissen',
        'Geen (contact met) buren',
        'Weinig/geen familie',
        'Ik heb geen (contact met) kinderen',
        'Mijn kind(eren) is/zijn overleden',
        'Anders'
      ])
    }
  },
  'clymz756n000008mg7yephght': {
    id: 'clymz756n000008mg7yephght',
    statement: 'Ik besteed graag tijd aan mijn hobby’s',
    icon: Hobbys,
    showFrequency: true,
    formFields: {
      question: 'Wat doet u zoal? (meerdere antwoorden mogelijk)',
      options: createOptions([
        'In de natuur zijn',
        '(Culturele) activiteiten',
        'Spellen',
        'Lezen, studeren',
        'Creatief bezig zijn',
        'Anders'
      ])
    }
  },
  'clymz7bet000108mg8m7398eu': {
    id: 'clymz7bet000108mg8m7398eu',
    statement: 'Ik werk of doe vrijwilligerswerk',
    icon: WerkOfVrijwilligerswerk,
    showFrequency: true,
    formFields: {
      question: 'Hoe ziet dit eruit voor u?',
      options: createOptions([
        'Ik werk voltijd',
        'Ik werk deeltijd',
        'Ik heb een eigen onderneming',
        'Ik doe vrijwilligerswerk',
        'Anders'
      ])
    }
  },
  'clymz7j36000208mgf3jt1951': {
    id: 'clymz7j36000208mgf3jt1951',
    statement: 'Ik werk niet (meer)',
    icon: WerkNiet,
    showFrequency: false,
    formFields: {
      question: 'Hoe ziet dit eruit voor u?',
      options: createOptions([
        'Ik ben zelf gestopt met werken',
        'Ik moest stoppen met werken',
        'Ik heb nooit gewerkt',
        'Anders'
      ])
    }
  },
  'clymz9sji000308mgdrvmer10': {
    id: 'clymz9sji000308mgdrvmer10',
    statement: 'Ik ben een sociaal persoon',
    icon: SociaalPersoon,
    showFrequency: false,
    formFields: {
      question: 'Wat betekent dit in uw geval?',
      options: createOptions([
        'Ik heb vrienden of vriendinnen',
        'Ik zie mijn familie regelmatig',
        'Ik heb veel contact met mijn buren',
        'Ik ben onderdeel van een vereniging',
        'Anders'
      ])
    }
  },
  'clymz9xpr000408mg6k6ghbt7': {
    id: 'clymz9xpr000408mg6k6ghbt7',
    statement: 'Ik krijg genoeg lichaamsbeweging',
    icon: Beweging,
    showFrequency: true,
    formFields: {
      question: 'Op welke manier krijgt u lichaamsbeweging?',
      options: createOptions([
        'Sporten',
        'Onderweg zijn (fietsen, wandelen)',
        'Ik doe fysiek werk',
        'Klussen, tuinieren',
        'Anders'
      ])
    }
  },
  'clymza6ee000508mgekt92ea7': {
    id: 'clymza6ee000508mgekt92ea7',
    statement: 'Religie of spiritualiteit speelt een grote rol in mijn leven',
    icon: Religie,
    showFrequency: false,
    formFields: {
      question: 'Hoe ziet dit eruit voor u?',
      options: createOptions([
        'Ik ga naar een kerk, moskee of synagoge',
        'Ik bezoek religieuze of spirituele bijeenkomsten',
        'Ik ben onderdeel van een gemeenschap',
        'Ik houd mij hier thuis mee bezig',
        'Anders'
      ])
    }
  },
  'clymzaesn000608mgf2ew96wd': {
    id: 'clymzaesn000608mgf2ew96wd',
    statement: 'Ik ervaar klachten',
    icon: Klachten,
    showFrequency: false,
    formFields: {
      question: 'Welke klachten ervaart u?',
      options: createOptions([
        'Ik kan dingen slecht onthouden',
        'Ik kan mijn aandacht moeilijk vasthouden',
        'Ik kan prikkels moeilijk verwerken',
        'Ik voel me soms een ander mens',
        'Dingen die ik graag doe, lukken niet altijd meer',
        'Anders'
      ])
    }
  },
  'clymzan8j000708mghdag8apr': {
    id: 'clymzan8j000708mghdag8apr',
    statement: 'Ik heb zelf een idee over wat er met mij aan de hand is',
    icon: Idee,
    showFrequency: false,
    formFields: {
      question: 'Wat denkt u dat er met u aan de hand is?',
      options: createOptions([
        'Ik denk dat mijn klachten horen bij het ouder worden',
        'Ik denk dat mijn klachten komen door slecht slapen',
        'Ik denk dat mijn klachten door dementie komen',
        'Ik denk dat mijn klachten door stress komen',
        'Ik denk dat mijn klachten door een ongeluk of ziekte komen',
        'Anders'
      ])
    }
  },
  'clymzavi1000808mg64mk58fj': {
    id: 'clymzavi1000808mg64mk58fj',
    statement: 'Ik kijk op tegen mijn afspraak met de arts',
    icon: TegenopZien,
    showFrequency: false,
    formFields: {
      question: 'Waarom kijkt u op tegen uw afspraak?',
      options: createOptions([
        'Ik weet niet wat ik kan verwachten',
        'Ik vind het spannend',
        'Ik heb de afspraak gemaakt op advies van iemand anders',
        'Ik weet niet of ik alles goed kan vertellen',
        'Anders'
      ])
    }
  },
  'clymzb2x8000908mg7a8efmie': {
    id: 'clymzb2x8000908mg7a8efmie',
    statement: 'Ik vind het lastig om om hulp te vragen',
    icon: HulpVragen,
    showFrequency: false,
    formFields: {
      question: 'Waarom vindt u het lastig om hulp te vragen?',
      options: createOptions([
        'Ik kan mij niet goed verwoorden',
        'Ik weet niet aan wie ik hulp kan vragen',
        'Ik wil anderen niet tot last zijn',
        'Ik schaam me voor mijn klachten',
        'Anders'
      ])
    }
  },
  'clymzb9cg000a08mg7ymb6zhp': {
    id: 'clymzb9cg000a08mg7ymb6zhp',
    statement: 'Ik ervaar op dit moment bepaalde emoties',
    icon: Emoties,
    showFrequency: false,
    formFields: {
      question: 'Wat merkt u aan uzelf?',
      options: createOptions([
        'Ik ben bezorgd',
        'Ik voel me angstig',
        'Ik voel me geïrriteerd',
        'Ik voel me eenzaam',
        'Ik voel me onrustig',
        'Ik ben emotioneel',
        'Anders'
      ])
    }
  },
  'clymzbgp3000b08mg0im662nd': {
    id: 'clymzbgp3000b08mg0im662nd',
    statement: 'Ik zit goed in mijn vel',
    icon: GoedInVel,
    showFrequency: false,
    formFields: {
      question: 'Wat betekent dit voor u?',
      options: createOptions([
        'Ik voel mij gezond',
        'Ik voel me vitaal',
        'Ik ben gelukkig',
        'Ik ben tevreden',
        'Ik voel me sterk',
        'Anders'
      ])
    }
  },
  'clymzbnrf000c08mg70e7hyue': {
    id: 'clymzbnrf000c08mg70e7hyue',
    statement: 'Ik heb iets meegemaakt dat veel invloed heeft gehad',
    icon: IetsMeegemaakt,
    showFrequency: false,
    formFields: {
      question: 'Om wat voor gebeurtenis gaat het?',
      options: createOptions([
        'Een persoonlijke gebeurtenis',
        'Een ongeluk',
        'Een gebeurtenis binnen mijn familie',
        'Een probleem met mijn gezondheid',
        'Anders'
      ])
    }
  },
  'clymzbvsj000d08mgb5wndx1z': {
    id: 'clymzbvsj000d08mgb5wndx1z',
    statement: 'Er speelt van alles in mijn leven op dit moment',
    icon: VanAllesSpeelt,
    showFrequency: false,
    formFields: {
      question: 'Wat speelt er zoal in uw leven?',
      options: createOptions([
        'Een persoonlijke gebeurtenis',
        'Een gebeurtenis in mijn familie',
        'Een probleem met mijn gezondheid',
        'Een grote verandering in mijn leven',
        'Anders'
      ])
    }
  },
  'clymzklb8000008l6hcsk4f7w': {
    id: 'clymzklb8000008l6hcsk4f7w',
    statement: 'Ik maak mij zorgen over de toekomst',
    icon: Toekomstzorgen,
    showFrequency: false,
    formFields: {
      question: 'Waar maakt u zich zorgen over?',
      options: createOptions([
        'Mijn woonsituatie',
        'Mijn werksituatie',
        'De situatie voor mijn naaste(n)',
        'Mijn dagelijkse activiteiten',
        'Autorijden en vervoer',
        'Geld- en/of regelzaken',
        'Anders'
      ])
    }
  },
  'clymzksx2000108l6hjal255x': {
    id: 'clymzksx2000108l6hjal255x',
    statement: 'Ik heb behoefte aan advies of meer informatie',
    icon: MeerInformatie,
    showFrequency: false,
    formFields: {
      question: 'Waarover heeft u advies of informatie nodig?',
      options: createOptions([
        'Over wat ik zelf aan mijn situatie kan doen',
        'Over wat er met mij aan de hand is',
        'Over hoe het nu verder gaat',
        'Over mogelijke behandelingen',
        'Anders'
      ])
    }
  },
  'clymzkyuz000208l6f8xx8i7b': {
    id: 'clymzkyuz000208l6f8xx8i7b',
    statement: 'Ik wil me graag voorbereiden op de toekomst',
    icon: Voorbereiden,
    showFrequency: false,
    formFields: {
      question: 'Waar wilt u zich op voorbereiden?',
      options: createOptions([
        'Mijn woonsituatie',
        'Mijn werksituatie',
        'De situatie voor mijn naaste(n)',
        'Mijn dagelijkse activiteiten',
        'Autorijden en vervoer',
        'Geld- en/of regelzaken',
        'Anders'
      ])
    }
  },
}

function createOptions(options) {
  return options.map(option => ({
    label: option,
    value: speakingurl(option, { lang: 'nl' })
  }))
}
